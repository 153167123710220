<template>
  <div class="side-bar">
    <ul>
      <li class="menu-item" :class="{ active: activeType == null }" @click="toggleType({ id: null })">
        <a class="menu-item-link" href="javascript:;">
          <img class="menu-item-img" :src="FolderIcon" alt="全部" />
          <span class="menu-item-name">全部</span>
        </a>
      </li>
      <li class="menu-item" :class="{ active: activeType == item.id }" v-for="(item, index) in typesList" :key="index"
        @click="toggleType(item)">
        <a class="menu-item-link" href="javascript:;">
          <img class="menu-item-img" :src="item.icon && $store.state.assetsUrl + item.icon" :alt="item.typeName" />
          <span class="menu-item-name">{{ item.typeName }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import FolderIcon from "../../assets/imgs/icon_folder.png";
export default {
  name: "SideBar",
  data() {
    return {
      typesList: [],
      activeType: this.$route.query.typeId || null,
      FolderIcon
    };
  },
  computed: {
    type() {
      return this.$route.query.typeId;
    },
    category() {
      return this.$route.params.category;
    },
  },
  created() {
    this.getTypesList();
  },
  methods: {
    // 根据分类加载对应废品类型
    getTypesList() {
      this.$get("/types/typesByCategory", {
        category: this.category,
      }).then((res) => {
        this.typesList = res.data || [];
      });
    },
    // 切换分类
    toggleType(item) {
      this.activeType = item.id;
      this.$router.replace(
        `/redirect${this.$route.path}?typeId=${this.activeType || ""}`
      );
    },
  },
  watch: {
    category(newV) {
      this.activeType = null;
      this.getTypesList();
    },
    type(newV) {
      this.activeType = newV;
      this.getTypesList();
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  .menu-item {
    font-size: 14px;
    padding: 2px;
    display: inline-block;

    .menu-item-link {
      padding: 0 10px;
      display: block;

      .menu-item-img {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 10px;
      }

      .menu-item-name {
        color: #c8c7cc;
        vertical-align: middle;
        width: calc(100% - 18px - 10px - 20px);
        overflow: hidden;
        text-overflow: ellipsis;
        height: 32px;
        line-height: 32px;
      }
    }

    &:hover,
    &.active {
      .menu-item-link {
        background: #0f5bff;
        border-radius: 4px;

        .menu-item-name {
          color: #fff;
        }
      }
    }
  }
}
</style>
